.container {
    --font-size: 1.25rem;
    --padding: 1rem;
    --color: black;

    padding-bottom: var(--padding);
}

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color);
    padding: var(--padding);
    border-bottom: solid 1px #ccc;
    margin-bottom: 1rem;
    font-size: var(--font-size);
}

.back {
    position: absolute;
    left: var(--padding);
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--font-size) * 1.5);
    height: calc(var(--font-size) * 1.5);
    color: var(--color);
}

.backIcon {
    height: var(--font-size);
}
