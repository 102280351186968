.container {
    height: 100%;
    overflow: auto;
}

.loader {
    height: 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    .spinner {
        display: flex;
        justify-content: center;
        width: 0;
        height: 0;
    }

    svg {
        width: 100%;
        height: 100%;

        &.loading {
            animation: rotation 1s infinite linear;
        }
    }
}

@keyframes rotation {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}
