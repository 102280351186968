@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Oswald', sans-serif;
}

html {
  font-size: 16px;
}

body {
    overscroll-behavior-y: contain;
}

#root {
    width: 100%;
    height: 100%;
}
