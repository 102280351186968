@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Oswald', sans-serif;
}

html {
  font-size: 16px;
}

body {
    overscroll-behavior-y: contain;
}

#root {
    width: 100%;
    height: 100%;
}

.Login_login__10IcO{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;height:100%;--form-items-color: #b23655}.Login_login__10IcO.Login_disabled__E3MH7{--form-items-color: #ccc}.Login_logo__2KrRe{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;height:50%}.Login_logo__2KrRe img{height:30%}.Login_form__1tdal{box-sizing:border-box;width:90%;max-width:512px;padding:0.5rem;text-align:center}.Login_input__2EuP6,.Login_button__3o8rl{box-sizing:border-box;display:block;width:100%;border:1px solid var(--form-items-color);font-size:1.3rem;padding:0.5rem;margin:0.5rem}.Login_button__3o8rl{color:white;background-color:var(--form-items-color);cursor:pointer}.Login_error__6SziI{display:block;color:var(--form-items-color)}

.Placeholder_container__1zENM{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:100%}

.Checkbox_checkbox__2pEz9{display:-webkit-flex;display:flex;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-align-items:center;align-items:center;padding:1rem 2rem;color:#929292}.Checkbox_checkbox__2pEz9.Checkbox_checked__20bOV{color:black}.Checkbox_checkbox__2pEz9 svg{height:2rem}.Checkbox_checkbox__2pEz9 label{margin-left:1rem}

.Checkboxes_container__2XG4f{--font-size: 1.25rem;--padding: 1rem;--color: black;padding-bottom:var(--padding)}.Checkboxes_header__24FlD{position:relative;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;color:var(--color);padding:var(--padding);border-bottom:solid 1px #ccc;margin-bottom:1rem;font-size:var(--font-size)}.Checkboxes_back__3zgj0{position:absolute;left:var(--padding);display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;width:calc(var(--font-size) * 1.5);height:calc(var(--font-size) * 1.5);color:var(--color)}.Checkboxes_backIcon__e9FHa{height:var(--font-size)}

.Item_link__k8qA3{text-decoration:none}.Item_item__3SHgj{--font-size: 1.25rem;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;color:black;padding:1rem;border-bottom:solid 1px #ccc;font-size:var(--font-size)}.Item_label__2vUpP{text-decoration:none}.Item_arrow__1t8iQ{height:var(--font-size)}

.ReloadableBox_container__3vPF3{height:100%;overflow:auto}.ReloadableBox_loader__1tptR{height:0;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;box-sizing:border-box}.ReloadableBox_loader__1tptR .ReloadableBox_spinner__1ukgx{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;width:0;height:0}.ReloadableBox_loader__1tptR svg{width:100%;height:100%}.ReloadableBox_loader__1tptR svg.ReloadableBox_loading__3JwoY{-webkit-animation:ReloadableBox_rotation__2a1KS 1s infinite linear;animation:ReloadableBox_rotation__2a1KS 1s infinite linear}@-webkit-keyframes ReloadableBox_rotation__2a1KS{from{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes ReloadableBox_rotation__2a1KS{from{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

.Budget_budget__IhGS1{margin:0.1rem;padding:0.5rem;color:white;background-color:#73a670;text-align:center}.Budget_budget__IhGS1.Budget_negative__Oj1ec{background-color:#cb7573}.Budget_title__2DmxJ{font-size:1rem}.Budget_rest__1yjwB{font-size:2rem;font-weight:bold}.Budget_details__3cjQk{display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;font-size:2rem}.Budget_prediction__2dNYd{font-size:2rem}@media (max-width: 1024px){.Budget_details__3cjQk{-webkit-flex-direction:column;flex-direction:column;font-size:1.5rem}.Budget_rests__23Eee,.Budget_prediction__2dNYd{font-size:1.2rem}.Budget_rests__23Eee{font-weight:bold}}

.BudgetSkeleton_budget__3v4OU{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;margin:0.1rem;padding:0.5rem;color:white;background-color:#aaa;text-align:center;-webkit-animation:BudgetSkeleton_skeleton-bg__1ThNu 1.5s infinite ease-in-out;animation:BudgetSkeleton_skeleton-bg__1ThNu 1.5s infinite ease-in-out}.BudgetSkeleton_title__3O9Hr{width:4rem;height:1rem;margin:1.25rem;border-radius:1rem;-webkit-animation:BudgetSkeleton_skeleton__2yPgQ 1.5s infinite ease-in-out;animation:BudgetSkeleton_skeleton__2yPgQ 1.5s infinite ease-in-out}.BudgetSkeleton_rest__4ygz1{width:6rem;height:2rem;margin:0.5rem 0 0.75rem;border-radius:1rem;-webkit-animation:BudgetSkeleton_skeleton__2yPgQ 1.5s infinite ease-in-out;animation:BudgetSkeleton_skeleton__2yPgQ 1.5s infinite ease-in-out}@-webkit-keyframes BudgetSkeleton_skeleton__2yPgQ{from{background-color:#a0a0a0}50%{background-color:#888}to{background-color:#a0a0a0}}@keyframes BudgetSkeleton_skeleton__2yPgQ{from{background-color:#a0a0a0}50%{background-color:#888}to{background-color:#a0a0a0}}@-webkit-keyframes BudgetSkeleton_skeleton-bg__1ThNu{from{background-color:#aaa}50%{background-color:#a8a8a8}to{background-color:#aaa}}@keyframes BudgetSkeleton_skeleton-bg__1ThNu{from{background-color:#aaa}50%{background-color:#a8a8a8}to{background-color:#aaa}}

.Total_container__3jTw1{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;height:100%;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;padding-bottom:5rem}.Total_placeholer__TPv2V{font-size:2rem;color:#ccc}.Total_main__Qt2fp{font-size:2.5rem}.Total_totals__2vs28{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-end;align-items:flex-end}.Total_amount__2Gate{font-size:1rem}.Total_currency__2iWp0{border:0;background-color:transparent;font-weight:bold;font-size:1em;color:#1e34b4}

.Main_container__3nnM7{position:relative;height:100%;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.Main_body__1cTmp{-webkit-flex-grow:1;flex-grow:1;background-color:#fafafa;overflow-y:auto}.Main_nav__Kao8r{height:4rem;padding-bottom:1.5rem;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;background-color:white;box-shadow:0 -1px 0.5rem 0 #00000085;z-index:1}.Main_navItem__37ZaX{display:block;-webkit-flex-grow:1;flex-grow:1;height:2rem;padding:0.5rem 0;color:grey;text-align:center}.Main_navItem__37ZaX.Main_active__1RpH5{color:#0c6ad6}.Main_navItem__37ZaX svg{height:100%}

