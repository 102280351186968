.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    --form-items-color: #b23655;

    &.disabled {
        --form-items-color: #ccc;
    }
}

.logo {
    display: flex;
    align-items: center;
    height: 50%;

    img {
        height: 30%;
    }
}

.form {
    box-sizing: border-box;
    width: 90%;
    max-width: 512px;
    padding: 0.5rem;
    text-align: center;
}

.input, .button {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid var(--form-items-color);
    font-size: 1.3rem;
    padding: 0.5rem;
    margin: 0.5rem;
}

.button {
    color: white;
    background-color: var(--form-items-color);
    cursor: pointer;
}

.error {
    display: block;
    color: var(--form-items-color);
}
