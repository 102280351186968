.budget {
    margin: 0.1rem;
    padding: 0.5rem;
    color: white;
    background-color: #73a670;
    text-align: center;

    &.negative {
        background-color: #cb7573;
    }
}

.title {
    font-size: 1rem;
}

.rest {
    font-size: 2rem;
    font-weight: bold;
}

.details {
    display: flex;
    justify-content: space-around;
    font-size: 2rem;
}

.prediction {
    font-size: 2rem;
}

@media (max-width: 1024px) {
    .details {
        flex-direction: column;
        font-size: 1.5rem;
    }

    .rests, .prediction {
        font-size: 1.2rem;
    }

    .rests {
        font-weight: bold;
    }
}
