.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 5rem;
}

.placeholer {
    font-size: 2rem;
    color: #ccc;
}

.main {
    font-size: 2.5rem;
}

.totals {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.amount {
    font-size: 1rem;
}

.currency {
    border: 0;
    background-color: transparent;
    font-weight: bold;
    font-size: 1em;
    color: #1e34b4;
}
